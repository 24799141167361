:root {
	font-size: 16px;
	font-family: 'Open Sans Condensed', sans-serif;
}
body {
	margin: 0;
	padding: 0;
	color: #34475D;
}
h1 {
	margin: 0;
	padding: 0;
	color: #34475D;
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
p {
	margin: 0;
	padding: 0;
	color: #34475D;
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
a {
	margin: 0;
	padding: 0;
	color: #34475D;
	text-decoration: none;
}
li {
	margin: 0;
	padding: 0;
	color: #34475D;
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
b {
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
span {
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
br {
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
img {
	&::-moz-selection {
		background: #95CEE5;
	}
	&::selection {
		background: #95CEE5;
	}
}
section#contact {
	.contactInfo {
		overflow: hidden;
		position: relative;
		button {
			margin: 1em auto;
		}
		.info {
			-webkit-transform: translateX(0%);
			transform: translateX(0%);
			-webkit-transition: -webkit-transform 300ms;
			transition: -webkit-transform 300ms;
			transition: transform 300ms;
			transition: transform 300ms, -webkit-transform 300ms;
		}
		.form {
			-webkit-transition: -webkit-transform 300ms;
			transition: -webkit-transform 300ms;
			transition: transform 300ms;
			transition: transform 300ms, -webkit-transform 300ms;
			-webkit-transform: translateX(100%);
			transform: translateX(100%);
			position: absolute;
			top: 0;
			min-height: 100%;
			width: 100%;
            box-sizing: border-box;
            padding: 1em;
            #reviewOutput {
                margin: 1em auto;
            }
            .form-group {
                margin: 0.25em 0em;
            }
            form {
                padding: 0em 1em 0 1em;
                text-align: left;
            }
			label {
				width: 5em;
				display: block;
                font-size: 1rem;
			}
            .checkbox-label {
                display: inline-block;
            }
			input {
				display: block;
                padding: 0.5em;
                width: 100%;
                box-sizing: border-box;

                &[type=checkbox] {
                    width: auto;
                    display: inline-block;
                }
			}
			textarea {
				display: block;
                padding: 0.5em;
                width: 100%;
                resize: vertical;
                box-sizing: border-box;
			}
			#reviewOutput {
				max-width: 70%;
				max-height: 5em;
				overflow: auto;
			}
		}
		.reviewForm {
			-webkit-transform: translateX(-100%);
			transform: translateX(-100%);
		}
	}
}
section#reviews {
	padding-bottom: 6em;
	&::after {
		clear: both;
		content: "";
		display: table;
	}
	.review {
		width: 50%;
		display: block;
		float: left;
	}
	.bottom {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-bottom: 1em;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		button {
			margin: 0.1em auto;
		}
	}
}
input {
	border: #34475D 0.125em solid;
	border-radius: 0.2em;
	background: transparent;
}
textarea {
	border: #34475D 0.125em solid;
	border-radius: 0.2em;
	background: transparent;
}
section {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	width: 100%;
	min-height: 100vh;
	background: #70E1D5;
	overflow: hidden;
	&:nth-child(1) {
		padding-top: 0em;
	}
	h1 {
		font-size: 4rem;
		font-weight: 700;
		font-style: italic;
		text-align: center;
		margin-top: 1.3em;
	}
	h2 {
		font-size: 4rem;
		font-weight: 700;
		font-style: italic;
		text-align: center;
		margin-top: 1.3em;
		font-size: 2rem;
		margin: 0.2em auto;
	}
	p {
		font-size: 1.3rem;
		text-align: center;
		width: 80%;
		margin: 2em auto;
	}
}
.transition {
	position: relative;
	width: 100%;
	height: 10vh;
	background: #95CEE5;
}
.anchorPoint {
	width: 100%;
	position: absolute;
	bottom: 2.6em;
}
.pager {
	width: 0;
	height: 0;
	border-left: 3em solid transparent;
	border-right: 3em solid transparent;
	border-top: 3em solid #E9D7DF;
	position: absolute;
	bottom: 5em;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	cursor: pointer;
	-webkit-transition: -webkit-transform 300ms;
	transition: -webkit-transform 300ms;
	transition: transform 300ms;
	transition: transform 300ms, -webkit-transform 300ms;
	&:hover {
		-webkit-transform: translate(-50%, 1em);
		transform: translate(-50%, 1em);
	}
}
.arrowTop {
	margin-left: -3em;
	margin-top: -4.2em;
	width: 0;
	height: 0;
	border-left: 3em solid transparent;
	border-right: 3em solid transparent;
	border-top: 3em solid #70E1D5;
}
#home {
	padding-bottom: 10em;
	img {
		width: 33.33%;
		max-width: 300px;
		height: auto;
		display: block;
		margin: 0 auto;
		margin-bottom: 2em;
	}
}
nav {
	position: fixed;
	left: 0;
	top: 0;
	background: #A7C0E1;
	width: 100%;
	z-index: 5;
	letter-spacing: 0.1em;
	height: 3.5625em;
	-webkit-transition: all 300ms;
	transition: all 300ms;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		position: fixed;
		li {
			font-size: 1.5rem;
			float: left;
			padding: 0.5em 0.5em;
			background: #97B0D1;
			-webkit-transition: all 300ms;
			transition: all 300ms;
			cursor: pointer;
			&:hover {
				background: #A7C0E1;
			}
		}
	}
}
.menu {
	display: none;
	position: fixed;
	margin-left: 1em;
	margin-top: 1em;
	cursor: pointer;
	width: 2.5em;
	height: 2.5em;
}
.menuLine {
	width: 2.5em;
	height: 0.33em;
	background: #34475D;
	margin: 0;
	margin-bottom: 0.5em;
}
#closeMenu {
	position: fixed;
	display: none;
	overflow: hidden;
	margin: 0;
	z-index: 1;
}
#closeImg {
	position: fixed;
	display: none;
	overflow: hidden;
	margin: 0;
	z-index: 7;
	top: 0;
	right: 0;
}
.closeLine {
	width: 2.5em;
	height: 0.33em;
	background: #34475D;
	&:nth-child(1) {
		margin-top: 1.222em;
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}
	&:nth-child(2) {
		margin-top: -0.333em;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
.pushMenu {
	position: fixed;
	width: 0%;
	height: 100%;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
	background: #95CEE5;
	-webkit-transition: width 300ms;
	transition: width 300ms;
}
.menuOpen {
	width: 50%;
}
.dark-background {
	-webkit-transition: background 600ms;
	transition: background 600ms;
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: -1;
}
.menuOpenLi {
	float: none;
	background: transparent;
	z-index: 11;
}
.menuOpenUl {
	display: block;
	margin-top: 3em;
}
.logo {
	font-family: 'Pacifico', cursive;
	font-size: 2rem;
	width: 3.8em;
	display: inline-block;
	background: white;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: translate 600ms, left 600ms;
	transition: translate 600ms, left 600ms;
	text-align: center;
	z-index: 6;
	line-height: 1em;
}
.contactInfo {
	margin: 0 auto;
	font-size: 1.5rem;
	text-align: center;
	font-weight: 900;
	max-width: 80%;
	background: #E9D7DF;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
	padding-bottom: 1.5em;
	margin-bottom: 1.5em;
}
.contact {
	margin-bottom: 0em;
	font-size: 1em;
}
.kvk {
	font-size: 0.6em;
	margin: 0.3em auto;
	margin-bottom: 1em;
	color: rgba(52, 71, 93, 0.3);
}
.profilePic {
	background-size: cover;
	background-blend-mode: multiply;
	background-color: #70E1D5;
	width: 10em;
	height: 10em;
	border-radius: 50%;
	border: 0.25em solid #95CEE5;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	margin: 0 auto;
}
footer {
	width: 100%;
	min-height: 10em;
	background: #34475D;
	position: relative;
	p {
		color: white;
		text-align: center;
		font-size: 1.6rem;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}
}
#imgView {
	position: fixed;
	display: none;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 2;
}
#imgViewImg {
	width: auto;
	height: 80%;
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
}
.galleryImg {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	width: 50%;
	height: 50vw;
	// float: left;
	background-size: cover;
	background-position: center center;
	border: 0.25em solid #70E1D5;
	background-repeat: no-repeat;
	-webkit-transition: -webkit-transform cubic-bezier(0.21, 0.29, 0.54, 1.96) 300ms, border 900ms;
	-webkit-transition: border 900ms, -webkit-transform cubic-bezier(0.21, 0.29, 0.54, 1.96) 300ms;
	transition: border 900ms, -webkit-transform cubic-bezier(0.21, 0.29, 0.54, 1.96) 300ms;
	transition: transform cubic-bezier(0.21, 0.29, 0.54, 1.96) 300ms, border 900ms;
	transition: transform cubic-bezier(0.21, 0.29, 0.54, 1.96) 300ms, border 900ms, -webkit-transform cubic-bezier(0.21, 0.29, 0.54, 1.96) 300ms;
	&:hover {
		-webkit-transform: scale(1.026);
		transform: scale(1.026);
		border: none;
	}
}
.galleryBig {
	width: 100%;
}
button {
	font-size: 1rem;
	padding: 1em 2em;
	color: #34475D;
	border: 1px solid #34475D;
	background: transparent;
	border-radius: 1.5em;
	font-family: sans-serif;
	margin: 2.5em auto;
	display: block;
	cursor: pointer;
}
.image-gallery-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.galleryImg {
    flex-basis: 50%;
    width: 50%;
    display: block;
    height: auto;
}
// .image-gallery-row {
// 	&::after {
// 		content: "";
// 		clear: both;
// 		display: table;
// 	}
// }
.photo-gallery-img-view {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: -1;
	left: 0;
	top: 0;
	-webkit-transition: opacity 600ms;
	transition: opacity 600ms;
	background: rgba(0, 0, 0, 0.4);
	opacity: 0;
	img {
		min-width: 80%;
        object-fit: contain;
		height: auto;
		max-height: 90%;
		max-width: 90%;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translateY(-50%) translateX(-50%);
		transform: translateY(-50%) translateX(-50%);
		z-index: 7;
	}
	.menu {
		position: fixed;
		overflow: hidden;
		margin: 0;
		z-index: 7;
		top: 2em;
		right: 2em;
	}
}
.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: warp;
	flex-wrap: warp;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}
.col {
	width: 100%;
}
.evenement {
	margin: 1em 0em;
	padding: 0em 1em;
	h2 {
		text-align: left;
		margin: 1em 0em;
		padding: 0;
	}
	p {
		text-align: left;
		margin: 1em 0em;
		padding: 0;
	}
	.event-icon {
		margin-right: 0.5em;
	}
	.event-name {
		margin: 0em;
	}
	.event-title {
		margin-top: 0em;
	}
	div {
		p {
			&:nth-child(1) {
				margin-top: 0em;
			}
		}
	}
	.event-img {
		width: 100%;
		height: auto;
	}
	.event {
		font-size: 1.3em;
		margin: 1em 0em;
	}
	.row {
		margin: 1em 0;
	}
}

@media screen and (max-width: 768px) {
	section#reviews {
		padding-bottom: 10em;
		.review {
			width: 100%;
		}
	}
    section#contact {
        .form {
            form {
                padding: 0 !important;
            }
        }
    }
}
@media screen and (max-width: 763px) {
	.logo {
		left: 50% !important;
		-webkit-transform: translateX(-50%) !important;
		transform: translateX(-50%) !important;
	}
	:root {
		font-size: 22px;
	}
	.pager {
		display: none;
	}
	section {
		&:nth-child(1) {
			h1 {
				margin-top: 0;
			}
		}
		h1 {
			font-size: 2.3rem;
			margin-top: 2em;
		}
	}
	.menuOpen {
		width: 100%;
	}
	#imgViewImg {
		width: 80%;
		height: auto;
	}
	footer {
		p {
			font-size: 1.3rem;
		}
	}
	#home {
		padding-bottom: 0em;
		padding-top: 4em;
	}
}
@media screen and (max-width: 482px) {
	.contactInfo {
		font-size: 1.1rem;
	}
}
@media screen and (max-width: 1306px) {
	.menu {
		display: block;
	}
	nav {
		background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(transparent));
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), transparent);
		ul {
			display: none;
		}
	}
	section {
		h1 {
			margin-top: 1em;
		}
		p {
			margin: 0 auto 2em auto;
		}
		&:nth-child(2) {
			margin: 0;
			padding-top: 3em;
			h1 {
				margin-top: 2em;
			}
		}
	}
	.logo {
		top: 0px;
		-webkit-box-shadow: 0px 0px 0.5em 0.5em rgba(255, 255, 255, 0.2);
		box-shadow: 0px 0px 0.5em 0.5em rgba(255, 255, 255, 0.2);
		background: rgba(255, 255, 255, 0.2);
	}
	.anchorPoint {
		bottom: -0.6em;
	}
}
@media screen and (max-height: 900px) {
	.pager {
		bottom: 0px;
	}
}
@media screen and (max-height: 780px) {
	.pager {
		display: none;
	}
	#home {
		padding-bottom: 0em;
	}
}
@media screen and (min-width: 700px) {
	.galleryImg {
		flex-basis: 33.3%;
        width: 33.3%;
	}
	.galleryBig {
		width: 66.66%;
	}
	button {
		margin: 5em auto;
	}
	.col {
		width: calc(50% - 2em);
		margin: 0em 1em;
	}
}
@media screen and (min-width: 1000px) {
	.galleryImg {
		// width: 25%;
		// height: 25vw;
        flex-basis: 25%;
        width: 25%;
	}
	.galleryBig {
		width: 50%;
	}
}
@media screen and (max-width: 700px) {
	.row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.evenement {
		.row {
			&:nth-child(even) {
				-webkit-box-orient: vertical;
				-webkit-box-direction: reverse;
				-ms-flex-direction: column-reverse;
				flex-direction: column-reverse;
			}
		}
	}
}
